export default [
  {
    title: 'Metadata',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Periods',
        route: 'periods.search-list',
      },
      {
        title: 'Premium Types',
        route: 'premiumtypes.search-list',
      },
    ],
  },
]
