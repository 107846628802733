import seance from './seance'
import rules from './rules'
import metadata from './metadata'
import debuggerR from './debugger'

export default [
  {
    title: 'Dashboard',
    route: 'app.home',
    icon: 'HomeIcon',
  },
  ...seance,
  ...rules,
  ...metadata,
  {
    title: 'Users',
    route: 'seances.search.user',
    icon: 'UsersIcon',
  },
  {
    title: 'Companies',
    route: 'seances.search.company',
    icon: 'ShoppingBagIcon',
  },
  ...debuggerR,
]
