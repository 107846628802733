export default [
  {
    title: 'Rules',
    route: 'rules.search-list',
    icon: 'CheckSquareIcon',
    /* children: [
      {
        title: 'Search',
        route: 'rules.search',
      },
    ], */
  },
]
