export default [
  {
    title: 'Seances',
    route: { name: 'seances.search-list', params: { q: '', r: '' } },
    icon: 'LayersIcon',
    /* children: [
      {
        title: 'Search',
        route: 'seances.search',
      },
    ], */
  },
]
